<template>
    <div class="my-1" @click="toggleSave()">
        <slot v-if="FEATURE.sidebar.customSidebarIcons" name="icon"></slot>
        <div v-else>
            <ion-icon class="w-8 h-8 drop-shadow icon-default cursor-pointer" :class="{'icon-save-active': isSaved }" :icon="icons.bookmark" />
            <p class="font-bold text-sm text-default dark:text-white">Uloziť</p>
        </div>
    </div>
</template>

<script>
import { bookmark } from 'ionicons/icons'
import { mapGetters } from 'vuex'

export default {
    props: {
        video: {
            requared: true,
        },
    },

    data() {
        return {
            isSaved: false,
            icons: {
                bookmark
            }
        }
    },
    computed: {
        ...mapGetters('auth', [
			'isLoggedIn',
		]),
    },

    mounted() {
        if(this.video.bookmark_by_active_user) {
            this.isSaved = true
        }
    },

    methods: {
        async toggleSave(){
            const video = this.video

            if (!this.isLoggedIn) {
                console.warn('redirect to login')
                // this.$router.push('/login')
                return
            }

			const value = this.video.bookmark_by_active_user ? 0 : 1
            video.bookmark_by_active_user = value
            if(value) {
                this.isSaved = true
            } else {
                this.isSaved = false
            }

			try {
				const data = {
					'type': 'bookmark',
					'value': value
				}

				await this.$axios.post_auth_data(`v1/userflag/lesson/${this.video.id}`, data)
			} catch (error) {
				video.bookmark_by_active_user = !value
				if(value) {
                    this.isSaved = false
                } else {
                    this.isSaved = true
                }

				this.$toast.error(error)
			}
        },
    }
}
</script>

<style lang="scss" scoped>

.icon-save-active {
    color: rgb(255,215,0)
}
</style>
