<template>
	<div class="w-12 h-12 mute-button absolute left-4 top-[10%] z-30 text-black cursor-pointer bg-white rounded-full p-2 border border-gray-200">
		<ion-icon v-if="mute" class="w-full h-full cursor-pointer" :icon="icons.volumeMute" />
		<ion-icon v-else class="w-full h-full cursor-pointer" :icon="icons.volumeHigh" />
	</div>
</template>

<script>
import { volumeHigh, volumeMute } from 'ionicons/icons'

export default {
	props: ["mute"],

	data() {
		return  {
			icons: {volumeHigh, volumeMute}
		}
	}
}
</script>