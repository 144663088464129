<template>
	<div>
		<ion-header>
			<ion-toolbar class="rounded-t-md text-center">
				<ion-title>{{ commentsCount }} {{ commentsCount === 1 ? 'Comment' : 'Comments' }}</ion-title>
				<ion-icon slot="end" class="text-xl mr-2 z-60 cursor-pointer" :icon="icons.close" @click="closeModal"></ion-icon>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<Z-comments :modelId="modelId" :model="model" ref="comments" />
		</ion-content>

		<ion-footer>
			<ion-toolbar>
				<ion-item>
					<ion-input v-model="newComment" placeholder="Add comment..." @keyup.enter="addComment()" />
				</ion-item>
			</ion-toolbar>
		</ion-footer>
	</div>
</template>

<script>
import { close } from 'ionicons/icons'
import ZComments from './z-comments.vue'

export default {
	components: {
		ZComments
	},

	props: {
		modelId: {
			type: Number,
			required: true
		},
		model: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			commentsCount: 0,
			newComment: '',
			icons: {
				close
			}
		}
	},

	async created(){
        const comments = await this.$axios.get_auth_data(`v1/comments/lesson/${this.modelId}`)
        this.commentsCount = comments.length
    },

	methods: {
		closeModal(){
			this.$emit('toggleComments')
		},
		
		async addComment() {
			try {
				await this.$refs.comments.addComment(this.newComment)
				this.newComment = ''
			} catch (error) {
				console.log(error)
			}
		},
	}
}
</script>

<style lang="scss" scoped>

ion-content {
	height: 60vh;
}

</style>