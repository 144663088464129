import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Feed from '@/plugins/lib@feed/feed.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Feed',
    component: Feed
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})

export default router
