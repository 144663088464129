<template>
	<swiper
		:speed="300"
		:direction="'vertical'"
		:mousewheel="true"
		:modules="modules"
		:simulateTouch="false"
		@swiper="onSwiper"
		@slideChange="onSlideChange"
		@reachEnd="loadVideos"
		class="w-full h-full relative"
	>
		<z-mute-btn v-if="FEATURE?.mute?.isGlobal" :mute="globalMute" @click="toggleGlobalMute"/>

		<template v-for="(video, index) in videos" :key="index">
			<swiper-slide class="bg-white">
				<z-feed-content :ref="`slide-${index}`" :video="video" :index="index" :sliderIndex="sliderIndex" :swiper="swiper" :globalMute="globalMute"/>
			</swiper-slide>
		</template>
		<transition name="fade">
			<div v-if="loadingContent" class="loader h-3 bottom-0 overflow-hidden absolute w-full"></div>
		</transition>
	</swiper>
</template>

<script>
import zFeedContent from './z-feed-content.vue'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { mapGetters } from 'vuex'
import { Mousewheel } from "swiper"
import zMuteBtn from './z-mute-btn.vue'

export default {
	components: {Swiper, SwiperSlide, zFeedContent, zMuteBtn},

	data(){
		return {
			videos: [],
			sliderIndex: 0,
			currentSlide: null,
			globalMute: this.FEATURE?.mute?.default ?? false,
			loadingContent: false,
			form: {
				email: "creator@wezeo.dev",
				password: "12345678"
			},
			modules: [Mousewheel],
		}
	},

	computed: {
		...mapGetters('auth', [
			'isLoggedIn',
		]),
	},

	async created(){
		await this.loadVideos()
		console.log(this.globalMute);
	},

	mounted(){

		if(this.isLoggedIn === undefined) console.warn('TENTO PLUGIN VYŽADUJE NAPOJENIE NA STORE (wAuth)')
	},

	methods: {
		async loadVideos(){
			this.loadingContent = true
			try {
				const response = await this.$axios.get_auth_data('v1/lesson/feed/recommended')
				// const videos = response.filter(video => video.content_shot != null)
				this.videos.push(...response)
				console.log("done " + this.videos.length);
			} catch(e) {
				this.$toast.error(e, 'top')
			}
			this.loadingContent = false
		},

		onSwiper(swiper) {
			this.swiper = swiper
		},

		onSlideChange() {
			const swiper = this.swiper;
			const oldSlide = this.currentSlide ?? this.$refs['slide-0'][0];
			(this.currentSlide = this.$refs[`slide-${swiper.realIndex}`][0])._onSlide(oldSlide);
			if (this.FEATURE?.mute?.isGlobal)
				this.currentSlide?._setMute(this.globalMute);
			this.sliderIndex = swiper.realIndex;
		},

		toggleGlobalMute() {
			this.globalMute ^= true;
			this.currentSlide?._setMute(this.globalMute);
		}
	}
}
</script>

<style lang="scss" scoped>
@import 'swiper/css';

.loader {
	background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(190,190,190,1) 50%, rgba(255,255,255,1) 100%);
}

.loader:before {
	content: "";
	position: absolute;
	left: -50%;
	height: 12px;
	width: 50%;
	background-color: rgb(255, 255, 255);
	-webkit-animation: lineAnimation 0.5s linear infinite;
	-moz-animation: lineAnimation 0.5s linear infinite;
	animation: lineAnimation 0.5s linear infinite;
}

@keyframes lineAnimation {
	0% {
		left: -40%;
	}
	50% {
		left: 20%;
		width: 80%;
	}
	100% {
		left: 100%;
		width: 100%;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>