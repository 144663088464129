<template>
	<ion-page>
		<ion-content class="relative">
			<z-feed />
		</ion-content>
	</ion-page>
</template>

<script>
import ZFeed from './components/z-feed.vue'
export default {
	components: {
		ZFeed,
	},
}
</script>

<style lang="scss" scoped>
</style>
