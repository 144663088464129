<template>
    <div class="my-1" @click="toggleLike()">
        <slot v-if="FEATURE.sidebar.customSidebarIcons" name="icon"></slot>
        <div v-else>
            <ion-icon class="w-8 h-8 drop-shadow icon-default cursor-pointer" :class="{'liked': isLiked }" :icon="icons.heart" />
            <p class="text-sm font-bold drop-shadow text-default dark:text-white">{{ isLiked ? video.likes + 1 : video.likes }}</p>
        </div>
    </div>
</template>

<script>
import { heart } from 'ionicons/icons'
import { mapGetters } from 'vuex'

export default {
    props: {
        video: {
            requared: true,
        },
    },
    data() {
        return {
            isLiked: false,
            icons: {
                heart
            }
        }
    },

    computed: {
        ...mapGetters('auth', [
			'isLoggedIn',
		]),
    },

    mounted() {
        if(this.video.like_by_active_user) {
            this.isLiked = true
        }
    },

    methods: {
        async toggleLike(){
            const video = this.video

			if (!this.isLoggedIn) {
                console.warn('redirect to login')
                // this.$router.push('/login')
                return
            }

			const value = this.video.like_by_active_user ? 0 : 1
            video.like_by_active_user = value
            if(value) {
                this.isLiked = true
            } else {
                this.isLiked = false
            }

			try {
				const data = {
					'type': 'like',
					'value': value
				}

				await this.$axios.post_auth_data(`v1/userflag/lesson/${this.video.id}`, data)
			} catch (error) {
				video.like_by_active_user = !value
				if(value) {
                    video.likes -= 1
                    this.isLiked = false
                } else {
                    video.likes += 1
                    this.isLiked = true
                }

				this.$toast.error(error)
			}
        },
    }
}
</script>

<style lang="scss" scoped>
.liked {
    transition: all 0.2s;
    color: rgba(254, 44, 85, 1);
}

</style>