<template>
	<div>
		<ion-item-sliding ref="slidingItem">
			<ion-item>
				
				<ion-avatar slot="start">
					<img :src="comment.author.avatar ? comment.author.avatar.path : require('./img/no-avatar.png')">
				</ion-avatar>

				<ion-label class="ion-text-wrap">
					<h2>{{ comment.author.username }}</h2>
					<p>{{ posted_at }}</p>
					<h3>{{ comment.content }}</h3>
				</ion-label>

			</ion-item>
			<ion-item-options side="end" v-if="isLoggedIn">
				<ion-item-option color="secondary" v-if="FEATURE.commentLike" @click="like()">
					<ion-icon slot="icon-only" :icon="heartOutline" />
				</ion-item-option>

				<ion-item-option color="warning" v-if="!isEditEnabled" @click="toggleEdit(true)">
					<ion-icon slot="icon-only" :icon="pencilOutline" />
				</ion-item-option>

				<ion-item-option color="danger" v-if="!isEditEnabled" @click="remove()">
					<ion-icon slot="icon-only" :icon="trashOutline" />
				</ion-item-option>

			</ion-item-options>
		</ion-item-sliding>

		<div v-if="editMode" class="edit-wrapper">
			<ion-label position="floating">Edit comment</ion-label>
			<ion-input v-model="editText" class="mb-1"></ion-input>
			
			<a class="action mr-1" @click="edit()">Save</a>
			<a class="action mr-1" @click="toggleEdit(false)">Cancel</a>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import { trashOutline, heartOutline, pencilOutline } from 'ionicons/icons'
import { mapGetters } from 'vuex'
import api from './api'

export default {
	props: {
		modelId: {
			type: Number,
			required: true
		},

		comment: {
			type: Object,
			required: true
		},

		isReply: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	data() {
		return {
			trashOutline,
			heartOutline,
			pencilOutline,
			editMode: false,
			editText: ''
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
		
		posted_at() {
			return moment(this.comment.created_at).format('MMMM Do YYYY')
		},
		
		isEditEnabled() {
			if (this.isReply) {
				return this.comment.author_id == this.user.id || this.user.groups && this.user.groups.includes('admin')
			}

			return this.comment.author_id == this.user.id || this.user.groups && this.user.groups.includes('admin')
		},
	},

	methods: {
		async remove() {
			try {
				await api.deleteComment(this.comment.id)

				this.$wToast.success()
				this.$emit('refreshComments')
			} catch (e) {
				this.$wToast.error(e)
			}

			this.closeSlidingItems()
		},

		toggleEdit(value) {
			this.editText = this.comment.content
			this.editMode = value

			this.closeSlidingItems()
		},

		async edit() {
			try {
				await api.editComment(this.comment.id, this.editText)
				this.editMode = false
				this.$wToast.success()
				this.$emit('refreshComments')
			} catch (e) {
				this.$wToast.error(e)
			}
		},

		closeSlidingItems() {
			this.$refs['slidingItem'].$el.close()
		}
	}
}
</script>

<style lang="sass" scoped>
ion-item
	--inner-padding-bottom: 20px
	--inner-padding-top: 20px

a.action
	font-size: 14px

.edit-wrapper
	border-bottom: 1px solid rgba(0, 0, 0, 0.2)
	padding: 15px 0
</style>