<template>
	<div class="w-full h-full" v-show="isShowing">

		<!-- Descriptions -->
		<template v-if="FEATURE.descriptions">
			<div class="absolute z-30 bottom-4 left-4">
				<z-video-desc :authorAvatar="video.author.avatar.path" :authorName="video.author.nickname" :videoName="video.name" :videoDescription="video.content_description" />
			</div>
		</template>

		<!-- Is video  -->
		<template v-if="videoObj">
			<!-- Mute -->
			<z-mute-btn v-if="FEATURE.mute && !FEATURE.mute.isGlobal" :mute="isMuted" @click="muteVideo()"/>

			<!-- Pause -->
			<template v-if="isPaused && !isLoading">
				<img v-if="FEATURE.pause.customPauseIcon" class="icon z-20 absolute top-1/2 left-1/2 pointer-events-none w-28" 
					:src="CONFIG.pause.customPauseSrc"
				/>
				<ion-icon v-else 
					class="icon z-20 absolute top-1/2 left-1/2 h-16 w-16 pointer-events-none opacity-70 text-gray-300"
					:src="icons.play"
				/>
			</template>
		</template>

		<!-- Loading -->
		<ion-icon v-if="isLoading" class="icon z-20 absolute top-1/2 left-1/2 h-12 w-12"
			:src="loadingSrc"
		/>

		<!-- Sidebar -->
		<template v-if="FEATURE.sidebar.customSidebar">
			<!-- Custom sidebar code HERE... -->
		</template>
		<z-sidebar v-else @openModal="toggleCommentsModal()" :video="video"></z-sidebar>

		<!-- Comments Modal -->
		<transition name="slide">
			<z-comments-modal v-if="FEATURE.actions.comments && areCommentsOpen" class="comments absolute bottom-0 w-full z-30" :modelId="video.id" 
			@toggleComments="toggleCommentsModal()" model="lesson" />
		</transition>
		
		<!-- Content  -->
		<div class="relative w-full h-full z-10 flex items-center overflow-hidden bg-black" @click="togglePause()">
			<video v-if="video.content_shot" class="w-full object-contain" :id="index" 
				webkit-playsinline="webkit-playsinline" playsinline loop ref="video">
				<source :src="video.content_shot" type="video/mp4">
				Video je nedostupné
			</video>
			<img v-else :src="video.content_thumbnail" class="w-full object-contain" ref="image">
		</div>
	</div>
</template>

<script>
import { play, chatbubbleEllipses, heart, arrowRedo, diamond, chatbox, volumeHigh, volumeMute } from 'ionicons/icons'

import zCommentsModal from '@/plugins/w@feature/comments/z-comments-modal.vue'
import zSidebar from './z-sidebar.vue'
import zVideoDesc from './z-video-description.vue'
import zMuteBtn from './z-mute-btn.vue'

export default {
	components: {zCommentsModal, zSidebar, zVideoDesc, zMuteBtn},

	props: {
		video: {
			required: true,
		},
		index: {
			required: true,
		},
		sliderIndex: {
			required: true,
		},
		swiper: {
			required: true,
		},
		globalMute: {
			required: true,
		}
	},

	data() {
		return {
			isPaused: false,
			isMuted: this.video.muted,
			isLoading: true,
			comments: null,
			isLiked: false,
			areCommentsOpen: false,
			// isShowing: true,
			visitCounted: false,
			icons: {
				play,
				chatbubbleEllipses,
				heart,
				arrowRedo,
				diamond,
				chatbox,
				volumeHigh,
				volumeMute,
			},
			videoObj: null,
			imgObj: null
		}
	},

	mounted() {
		this._setup()
	},

	computed: {
		loadingSrc(){
			return this.FEATURE.loading.customLoadingIcon ? this.CONFIG.loading.customLoadingSrc : require('@/plugins/lib@feed/_img/_default-spinner.svg')
		},

		isShowing(){
			const videoCount = 2
			return this.sliderIndex < this.index + videoCount && this.sliderIndex + videoCount > this.index
		}
	},
	
	methods: {
		_setup() {
			const video = this.videoObj = this.$refs['video']
			if (!video)
			{
				const img = this.imgObj = this.$refs['image']
				img.onload = () => {
					this.isLoading = false
				}

				return
			}

			const swiper = this.swiper
			if(this.index == 0) {
				// video ked ma ready state 0-3 tak neni este pripravene na prehratie
				// video ak ma ready state 4, video ma dostatok dat na normalne prehratie co je potreba
				if(video.readyState < 4){
					swiper.allowTouchMove = false
					this._countVisit()
					video.play()
				}

				this.isPaused = video.paused
				// this.isShowing = true
			}

			video.onwaiting = () => {
				this.isLoading = true
			}

			video.oncanplay = () => {
				this.isLoading = false
				swiper.allowTouchMove = true
			}
			
			video.onplay = () => {
				this.isPaused = false
			}

			video.onpause = () => {
				this.isPaused = true
			}

			if (this.globalMute)
				this.muteVideo();
		},

		_onSlide(prevVid) {
			if (prevVid?.videoObj)
			{
				prevVid.videoObj.currentTime = 0
				prevVid.videoObj.pause()
			}
			
			// const video = this.videoObj
			// if(!this.isMuted){
			//	 video.muted = false
			// }

			// this.isShowing = true
			this.videoObj?.play()
			
			this._countVisit()
		},

		_setMute(mute) {
			if (this.videoObj)
				this.isMuted = this.videoObj.muted = mute;
		},

		muteVideo() {
			this._setMute(!this.isMuted);
		},

		togglePause() {
			if(!this.videoObj || this.isLoading) return
			
			const video = this.videoObj
			return video.paused ? video.play() : video.pause()
		},

		toggleCommentsModal() {
			const swiper = this.swiper

			this.areCommentsOpen = !this.areCommentsOpen

			if(this.areCommentsOpen){
				swiper.allowSlideNext, swiper.allowSlidePrev, swiper.allowTouchMove = false
				swiper.mousewheel.disable()
			} else {
				swiper.allowSlideNext, swiper.allowSlidePrev, swiper.allowTouchMove = true
				swiper.mousewheel.enable()
			}
		},

		async _countVisit() {
			if(!this.visitCounted) {
				try {
					await this.$axios.get_auth_data(`v1/lessons/${this.video.id}`)
					this.visitCounted = true
				} catch(error) {
					this.$toast.error(error, 'top')
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.icon {
	transform: translate(-50%, -50%);
}

.sidebar, .mute-button {
	transform: translate3d(0, 0, 200px);
}

.comments {
	transform: translate3d(0, 0, 300px);
}

.slide-enter-active, .slide-leave-active {
	transition: margin-bottom .15s ease-out;
	margin-bottom: -70vh;
}

.slide-enter, .slide-leave-to {
	margin-bottom: -70vh;
}

.slide-enter-to, .slide-leave {
	margin-bottom: 0vh;
}

</style>