<template>
	<div class="sidebar z-30 w-12 h-72 absolute right-4 bottom-[10%] flex flex-col items-center text-center">
		<div class="flex flex-col items-center text-white">		  

			<transition name="collapse">
				<div v-if="isShowing" class="body">
					<z-likes v-if="FEATURE.actions.like" :video="video" ></z-likes>

					<z-comment-icon v-if="FEATURE.actions.comments" :modelId="video.id" @click="$emit('openModal')" ></z-comment-icon>

					<z-save v-if="FEATURE.actions.save" :video="video" ></z-save>

					<z-shares v-if="FEATURE.actions.share" :video="video" ></z-shares>
				</div>
			</transition>

			<img v-if="FEATURE.actions.collapsable" :src="isShowing ? CONFIG?.collapsable.customCollapseSrc : (CONFIG?.collapsable.customUncollapseSrc ?? CONFIG?.collapsable.customCollapseSrc)" 
				class="collapse-btn w-16 h-16 absolute -bottom-10 text-black cursor-pointer rounded-full border border-gray-200" @click="isShowing ^= true" />
		</div>
	</div>
</template>

<script>
import zSave from '@/plugins/w@feature/save/z-save.vue'
import zLikes from '@/plugins/w@feature/like/z-like.vue'
import zShares from '@/plugins/w@feature/share/z-share.vue'
import ZCommentIcon from '../../w@feature/comments/z-comment-icon.vue'

export default {
	components: {zLikes, zShares, ZCommentIcon, zSave},

	props: {
		video: {
			required: true,
			type: Object
		},
	},

	data() {
		return {
			isShowing: true
		}
	}
}
</script>

<style lang="scss" scoped>
.collapse-enter-active, .collapse-leave-active {
	transition: 0.16s linear;
}

.collapse-enter-from, .collapse-leave-to {
	opacity: 0;
	transform: scale(100%, 50%) translate(0px, 110px);
	transform-origin: bottom;
}
</style>