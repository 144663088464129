<template>
	<div v-if="FEATURE.descriptions.author" class="flex items-center drop-shadow">
		<ion-avatar class="mr-3">
			<img :src="authorAvatar">
		</ion-avatar>
		<p class="font-bold">@{{authorName}}</p>
	</div>
	<template v-if="FEATURE.descriptions.video">
		<p class="mt-4 font-bold drop-shadow">{{videoName}}</p>
		<p class="drop-shadow">{{videoDescription}}</p>
	</template>
</template>

<script>
export default {
	props: {
		authorAvatar: {
			required: true
		},
		authorName:  {
			required: true
		},
		videoName:  {
			required: true
		},
		videoDescription:  {
			required: true
		}
	}
}
</script>