<template>
    <div class="my-1" @click="shareVideo()">
        <slot v-if="FEATURE.sidebar.customSidebarIcons" name="icon"></slot>
        <div v-else>
            <ion-icon class="w-8 h-8 drop-shadow icon-default cursor-pointer" :icon="icons.arrowRedo" />
            <p class="font-bold text-sm text-default dark:text-white">Zdielať</p>
        </div>
    </div>
</template>

<script>
import { arrowRedo } from 'ionicons/icons'
import { Share } from '@capacitor/share';


export default {
    props: {
        video: {
            requared: true,
        },
    },
    data() {
        return {
            icons: {
                arrowRedo
            }
        }
    },

    methods: {
        async shareVideo(){
            const { value: isShareable } = await Share.canShare()

            if(!isShareable){
                navigator.clipboard.writeText(`https://gamechangers.wezeo.show/@${encodeURI(this.video.author.nickname)}v/${this.video.id}/${encodeURI(this.video.name)}`);
                this.$toast.success('Link copied', 'top')
                return
            }

            await Share.share({
				title: this.video.name,
				text: `Ahoj! Toto by ste si mali pozrieť: ${this.video.content_description || ''}`,
				url: `https://gamechangers.wezeo.show/@${encodeURI(this.video.author.nickname)}v/${this.video.id}/${encodeURI(this.video.name)}`,
				dialogTitle: `Share ${this.video.name}`
			})
        }
    }
}
</script>
