export default {
	FEATURE: {
		actions: {
			save: true,
			like: true,
			comments: true,
			share: true,

			collapsable: true
		},
		sidebar: {
			customSidebar: false,
			customSidebarIcons: false,
		},
		loading: {
			customLoadingIcon: false,
		},
		pause: {
			customPauseIcon: false,
		},
		mute: {
			isGlobal: true,
		}
	},
	CONFIG: {
		loading: {
			customLoadingSrc: require('../plugins/lib@feed/_img/spinner.svg')
		},
		pause: {
			customPauseSrc: require('../plugins/lib@feed/_img/pause.png')
		},
		collapsable: {
			customCollapseSrc: require('../plugins/lib@feed/_img/show-sidemenu.5e399646.png'),
			customUncollapseSrc: require('../plugins/lib@feed/_img/hide-sidemenu.b1d07d83.png')
		}
	}
}