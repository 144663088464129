<template>
    <div class="my-1">
        <div v-if="FEATURE.sidebar.customSidebarIcons" @click="$emit('openModal')">
            <slot name="icon"></slot>
        </div>
        <div v-else>
            <ion-icon class="w-8 h-8 drop-shadow icon-default cursor-pointer" :icon="icons.chatbubbleEllipses" @click="$emit('openModal')" />
            <p class="text-sm font-bold drop-shadow text-default dark:text-white">{{ commentsCount }}</p>
        </div>
    </div>
</template>

<script>
import { chatbubbleEllipses } from 'ionicons/icons'

export default {
    props: {
        modelId: {
            requared: true,
            type: Number
        }
    },
    data() {
        return {
            commentsCount: 0,
            icons: {
                chatbubbleEllipses
            }
        }
    },

    async created(){
        const comments = await this.$axios.get_auth_data(`v1/comments/lesson/${this.modelId}`)
        this.commentsCount = comments.length
    },
}
</script>