export default {
	FEATURE: {
		actions: {
			save: true,
			like: true,
			comments: true,
			share: true,

			collapsable: false
		},
		sidebar: {
			customSidebar: false,
			customSidebarIcons: false,
		},
		loading: {
			customLoadingIcon: true,
		},
		pause: {
			customPauseIcon: true,
		},
		descriptions: {
			video: true,
			author: true
		},
		mute: {
			isGlobal: false,
			default: false,
		}
	},
	CONFIG: {
		loading: {
			customLoadingSrc: require('../plugins/lib@feed/_img/spinner.svg')
		},
		pause: {
			customPauseSrc: require('../plugins/lib@feed/_img/pause.png')
		}
	}
}