<template>
	<div class="comments-wrapper h-full">
		<p v-if="!comments || !comments.length" class="h-full flex items-center justify-center">No comments yet</p>
		<div v-else-if="comments.length">
			<div v-for="comment in comments" :key="comment.id" >
				<Z-comment :comment="comment" :modelId="modelId" @refreshComments="loadComments()" />

				<div v-for="reply in comment.replies" :key="reply.id" class="replies-wrapper">
					<Z-comment :comment="reply" :isReply="true" :modelId="modelId" @refreshComments="loadComments()" />
				</div>
			</div>
		</div>

		<p v-if="!isLoggedIn" class="text-center">
			Yo have to be <router-link to="/prihlasit-sa">logged in</router-link> in order to comment.
		</p>

		<div v-else-if="isLoggedIn && !FEATURE.comments">
			<h5>Add comment</h5>

			<ion-textarea v-model="text" placeholder="Your comment..." autocomplete="none" rows="4" class="mb-3"></ion-textarea>
			<ion-button expand="block" @click="addComment()" :disabled="$wToast.isLoading()">Add</ion-button>
		</div>
		
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ZComment from './z-comment.vue'
import api from './api'

export default {
	props: {
		modelId: {
			type: Number,
			required: true
		},
		model: {
			type: String,
			required: true
		}
	},

	components: {
		ZComment
	},

	data() {
		return {
			text: '',
			comments: null
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		])
	},

	async created() {
		await this.loadComments()
	},

	methods: {
		async addComment(text=null) {
			try {
				this.$wToast.clear_loading()
				await api.addComment(this.model, this.modelId, text || this.text)
				this.$wToast.success()
				this.text = ''
			} catch (e) {
				this.$wToast.error(e)
			}

			this.loadComments()
		},
		
		async loadComments() {
			try {
				const comments = await api.getByModelId(this.model, this.modelId)
				comments.reverse()
				comments.map(comment => comment.replies?.reverse())

				this.comments = comments
			} catch (e) {
				this.comments = []
				this.comments.error = true
				this.$wToast.error(e)
			}
		}
	},
}
</script>

<style lang="sass" scoped>
.comments-wrapper
	h4
		margin-bottom: 30px

	.replies-wrapper
		padding-left: 30px
</style>