import App from '@/App.vue'
import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue';
import router from './router'

/* W plugins */
import store from '@/store'
import auth from '@/plugins/w/auth'
import axios from '@/plugins/w/axios'
import wToast from '@/plugins/w/toast'
import ionic from '@/plugins/w/ionic'
import i18n from '@/plugins/w/i18n'
import { Options as WOptions } from '@/plugins/w/w/types'

const options: WOptions = {
	i18n: {
		languages: [
			{title: "Slovenský", flag: "sk", value: "sk"},
			{title: "English", flag: "en", value: "en"}
		],
		defaultLanguage: 'sk'
	}
}

import '@/index.css'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/plugins/app/_theme/variables.css';

const app = createApp(App)
  .use(store)
  .use(auth)
  .use(axios)
  .use(IonicVue)
  .use(ionic)
  .use(wToast)
  .use(router)
  .use(i18n, options)

router.isReady().then(() => {
  app.mount('#app')
})

// Featureconfig
import wConfig from '@/plugins/w@cli/featureconfig/index'
import defaultConfig from '@/config/default'
import customConfig from '@/_config/config'

wConfig.addConfigAndFeatureVariables(app, customConfig, defaultConfig )